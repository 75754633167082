import React from "react";
import Headline from "../Headline";

export default function AGB() {
  return (
    <div>
      <Headline title="AGB" />
      <div>
        1) Geltungsbereich
        <br />
        1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") der
        Michelle Yasmine Laskowski, handelnd unter "Michi's Illustrations"
        (nachfolgend "Verkäufer"), gelten für alle Verträge über die Lieferung
        von Waren, die ein Verbraucher oder Unternehmer (nachfolgend "Kunde")
        mit dem Verkäufer hinsichtlich der vom Verkäufer auf der
        Internet-Handelsplattform Etsy (nachfolgend "Etsy") dargestellten Waren
        abschließt. Hiermit wird der Einbeziehung von eigenen Bedingungen des
        Kunden widersprochen, es sei denn, es ist etwas anderes vereinbart.
        <br />
        1.2 Für Verträge über die Lieferung digitaler Inhalte gelten diese AGB
        entsprechend, sofern insoweit nicht ausdrücklich etwas Abweichendes
        geregelt ist.
        <br />
        1.3 Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein
        Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer
        gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet
        werden können. Unternehmer im Sinne dieser AGB ist eine natürliche oder
        juristische Person oder eine rechtsfähige Personengesellschaft, die bei
        Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder
        selbständigen beruflichen Tätigkeit handelt.
        <br />
        1.4 Digitale Inhalte im Sinne dieser AGB sind alle nicht auf einem
        körperlichen Datenträger befindlichen Daten, die in digitaler Form
        hergestellt und vom Verkäufer unter Einräumung bestimmter in diesen AGB
        genauer geregelten Nutzungsrechte, bereitgestellt werden.
        <br />
        <br />
        2) Vertragsschluss
        <br />
        2.1 Mit der Einstellung eines Artikels bei Etsy gibt der Verkäufer ein
        verbindliches Angebot zum Verkauf dieses Artikels ab.
        <br />
        2.2 Der Kunde kann das Angebot des Verkäufers über das bei Etsy
        vorgehaltene Online-Bestellformular annehmen. Hierzu muss der Kunde den
        gewünschten Artikel zunächst in den virtuellen Warenkorb bei Etsy
        einlegen und anschließend die von Etsy vorgegebenen Bestellschritte
        durchlaufen. Durch Anklicken des den Bestellprozess abschließenden
        Buttons erklärt der Kunde die Annahme des Angebots, wodurch ein
        Kaufvertrag über die vom Kunden zuvor ausgewählte Ware zustande kommt.
        <br />
        2.3 Der Vertragstext wird vom Verkäufer gespeichert und dem Kunden nach
        Vertragsschluss nebst den vorliegenden AGB und Kundeninformationen in
        Textform (z. B. E-Mail, Fax oder Brief) zugeschickt. Eine darüber hinaus
        gehende Zugänglichmachung des Vertragstextes durch den Verkäufer selbst
        findet nicht statt.
        <br />
        2.4 Vor verbindlicher Abgabe der Bestellung kann der Kunde mögliche
        Eingabefehler durch aufmerksames Lesen der auf dem Bildschirm
        dargestellten Informationen erkennen. Ein wirksames technisches Mittel
        zur besseren Erkennung von Eingabefehlern kann dabei die
        Vergrößerungsfunktion des Browsers sein, mit deren Hilfe die Darstellung
        auf dem Bildschirm vergrößert wird. Seine Eingaben kann der Kunde vor
        verbindlicher Abgabe der Bestellung im Rahmen des elektronischen
        Bestellprozesses mit den üblichen Tastatur- und Mausfunktionen
        korrigieren.
        <br />
        2.5 Für den Vertragsschluss steht ausschließlich die deutsche Sprache
        zur Verfügung.
        <br />
        <br />
        3) Widerrufsrecht
        <br />
        3.1 Verbrauchern steht grundsätzlich ein Widerrufsrecht zu.
        <br />
        3.2 Nähere Informationen zum Widerrufsrecht ergeben sich aus der
        Widerrufsbelehrung des Verkäufers.
        <br />
        <br />
        4) Preise und Zahlungsbedingungen
        <br />
        4.1 Die vom Verkäufer angegebenen Preise sind Gesamtpreise und enthalten
        die gesetzliche Umsatzsteuer. Gegebenenfalls zusätzlich anfallende
        Liefer- und Versandkosten werden in der jeweiligen Produktbeschreibung
        gesondert angegeben.
        <br />
        4.2 Bei Lieferungen in Länder außerhalb der Europäischen Union können im
        Einzelfall weitere Kosten anfallen, die der Verkäufer nicht zu vertreten
        hat und die vom Kunden zu tragen sind. Hierzu zählen beispielsweise
        Kosten für die Geldübermittlung durch Kreditinstitute (z.B.
        Überweisungsgebühren, Wechselkursgebühren) oder einfuhrrechtliche
        Abgaben bzw. Steuern (z.B. Zölle). Solche Kosten können in Bezug auf die
        Geldübermittlung auch dann anfallen, wenn die Lieferung nicht in ein
        Land außerhalb der Europäischen Union erfolgt, der Kunde die Zahlung
        aber von einem Land außerhalb der Europäischen Union aus vornimmt.
        <br />
        4.3 Die Zahlungsmöglichkeit/en wird/werden dem Kunden im Angebot des
        Verkäufers bei Etsy mitgeteilt.
        <br />
        4.4 Bei Zahlung mittels einer über den Zahlungsdienst "Etsy Payments"
        angebotenen Zahlungsmethode erfolgt die Zahlungsabwicklung über die Etsy
        Ireland UC, 66/67 Great Strand Street, Dublin 1, Irland (nachfolgend
        "Etsy UC") bzw. - wenn die Zahlung in den Währungen US-Dollar oder
        kanadische Dollar erfolgt - über die die Etsy Inc., 117 Adams Street,
        Brooklyn, NY 11201, Vereinigte Staaten von Amerika (nachfolgend "Etsy
        Inc."). Etsy UC bzw. Etsy Inc. ermöglicht dem Kunden im Rahmen der
        Zahlungsabwicklung über Etsy Payments die Nutzung verschiedener
        Zahlungsarten. Für die Abwicklung von Zahlungen kann sich Etsy UC bzw.
        Etsy Inc. der Zahlungsdienste Dritter bedienen. Die konkret für die
        jeweilige Ware verfügbare(n) Zahlungsart(en) wird/ werden dem Kunden im
        jeweiligen Angebot des Verkäufers bzw. im Rahmen der Bestellabwicklung
        angezeigt. Weitere Informationen zu sowie die Nutzungsbedingungen von
        Etsy Payments sind hier abrufbar:
        etsy.com/de/legal/etsy-payments/?ref=list
        <br />
        4.5 Bei Auswahl der Zahlungsart "PayPal" erfolgt die Zahlungsabwicklung
        über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A.,
        22-24 Boulevard Royal, L-2449 Luxembourg unter Geltung der
        PayPal-Nutzungsbedingungen, einsehbar unter
        paypal.com/de/webapps/mpp/ua/useragreement-full. Dies setzt u. a.
        voraus, dass der Kunde ein PayPal-Konto eröffnet bzw. bereits über ein
        solches Konto verfügt.
        <br />
        <br />
        5) Liefer- und Versandbedingungen
        <br />
        5.1 Die Lieferung von Waren erfolgt auf dem Versandweg an die vom Kunden
        angegebene Lieferanschrift, sofern nichts anderes vereinbart ist. Bei
        der Abwicklung der Transaktion ist die in der Bestellabwicklung bei Etsy
        angegebene Lieferanschrift maßgeblich.
        <br />
        5.2 Scheitert die Zustellung der Ware aus Gründen, die der Kunde zu
        vertreten hat, trägt der Kunde die dem Verkäufer hierdurch entstehenden
        angemessenen Kosten. Dies gilt im Hinblick auf die Kosten für die
        Hinsendung nicht, wenn der Kunde sein Widerrufsrecht wirksam ausübt. Für
        die Rücksendekosten gilt bei wirksamer Ausübung des Widerrufsrechts
        durch den Kunden die in der Widerrufsbelehrung des Verkäufers hierzu
        getroffene Regelung.
        <br />
        5.3 Der Verkäufer behält sich das Recht vor, im Falle nicht richtiger
        oder nicht ordnungsgemäßer Selbstbelieferung vom Vertrag zurückzutreten.
        Dies gilt nur für den Fall, dass die Nichtlieferung nicht vom Verkäufer
        zu vertreten ist und dieser mit der gebotenen Sorgfalt ein konkretes
        Deckungsgeschäft mit dem Zulieferer abgeschlossen hat. Der Verkäufer
        wird alle zumutbaren Anstrengungen unternehmen, um die Ware zu
        beschaffen. Im Falle der Nichtverfügbarkeit oder der nur teilweisen
        Verfügbarkeit der Ware wird der Kunde unverzüglich informiert und die
        Gegenleistung unverzüglich erstattet.
        <br />
        5.4 Digitale Inhalte werden dem Kunden ausschließlich in elektronischer
        Form wie folgt überlassen:
        <br />
        - per Download
        <br />
        <br />
        6) Einräumung von Nutzungsrechten für digitale Inhalte
        <br />
        6.1 Sofern sich aus der Inhaltsbeschreibung des Verkäufers bei Etsy
        nichts anderes ergibt, räumt der Verkäufer dem Kunden an den
        überlassenen Inhalten das nicht ausschließliche, örtlich und zeitlich
        unbeschränkte Recht ein, die überlassenen Inhalte ausschließlich zu
        privaten Zwecken zu nutzen.
        <br />
        6.2 Eine Weitergabe der Inhalte an Dritte oder die Erstellung von Kopien
        für Dritte außerhalb des Rahmens dieser AGB ist nicht gestattet, soweit
        nicht der Verkäufer einer Übertragung der vertragsgegenständlichen
        Lizenz an den Dritten zugestimmt hat.
        <br />
        6.3 Die Rechtseinräumung wird erst wirksam, wenn der Kunde die
        vertraglich geschuldete Vergütung vollständig geleistet hat. Der
        Verkäufer kann eine Benutzung der vertragsgegenständlichen Inhalte auch
        schon vor diesem Zeitpunkt vorläufig erlauben. Ein Übergang der Rechte
        findet durch eine solche vorläufige Erlaubnis nicht statt.
        <br />
        <br />
        7) Eigentumsvorbehalt
        <br />
        Tritt der Verkäufer in Vorleistung, behält er sich bis zur vollständigen
        Bezahlung des geschuldeten Kaufpreises das Eigentum an der gelieferten
        Ware vor.
        <br />
        <br />
        8) Mängelhaftung
        <br />
        8.1 Ist die Kaufsache mangelhaft, gelten die Vorschriften der
        gesetzlichen Mängelhaftung.
        <br />
        8.2 Abweichend hiervon gilt bei gebrauchten Waren: Mängelansprüche sind
        ausgeschlossen, wenn der Mangel erst nach Ablauf eines Jahres ab
        Ablieferung der Ware auftritt. Mängel, die innerhalb eines Jahres ab
        Ablieferung der Ware auftreten, können innerhalb der gesetzlichen
        Verjährungsfrist geltend gemacht werden. Die Verkürzung der
        Haftungsdauer auf ein Jahr gilt jedoch nicht
        <br />
        - für Sachen, die entsprechend ihrer üblichen Verwendungsweise für ein
        Bauwerk verwendet worden sind und dessen Mangelhaftigkeit verursacht
        haben,
        <br />
        - für Schadensersatz- und Aufwendungsersatzansprüche des Kunden, sowie
        <br />
        - für den Fall, dass der Verkäufer den Mangel arglistig verschwiegen
        hat.
        <br />
        8.3 Handelt der Kunde als Verbraucher, so wird er gebeten, angelieferte
        Waren mit offensichtlichen Transportschäden bei dem Zusteller zu
        reklamieren und den Verkäufer hiervon in Kenntnis zu setzen. Kommt der
        Kunde dem nicht nach, hat dies keinerlei Auswirkungen auf seine
        gesetzlichen oder vertraglichen Mängelansprüche.
        <br />
        <br />
        9) Besondere Bedingungen für die Verarbeitung von Waren nach bestimmten
        Vorgaben des Kunden
        <br />
        9.1 Schuldet der Verkäufer nach dem Inhalt des Vertrages neben der
        Warenlieferung auch die Verarbeitung der Ware nach bestimmten Vorgaben
        des Kunden, hat der Kunde dem Betreiber alle für die Verarbeitung
        erforderlichen Inhalte wie Texte, Bilder oder Grafiken in den vom
        Betreiber vorgegebenen Dateiformaten, Formatierungen, Bild- und
        Dateigrößen zur Verfügung zu stellen und ihm die hierfür erforderlichen
        Nutzungsrechte einzuräumen. Für die Beschaffung und den Rechteerwerb an
        diesen Inhalten ist allein der Kunde verantwortlich. Der Kunde erklärt
        und übernimmt die Verantwortung dafür, dass er das Recht besitzt, die
        dem Verkäufer überlassenen Inhalte zu nutzen. Er trägt insbesondere
        dafür Sorge, dass hierdurch keine Rechte Dritter verletzt werden,
        insbesondere Urheber-, Marken- und Persönlichkeitsrechte.
        <br />
        9.2 Der Kunde stellt den Verkäufer von Ansprüchen Dritter frei, die
        diese im Zusammenhang mit einer Verletzung ihrer Rechte durch die
        vertragsgemäße Nutzung der Inhalte des Kunden durch den Verkäufer diesem
        gegenüber geltend machen können. Der Kunde übernimmt hierbei auch die
        angemessenen Kosten der notwendigen Rechtsverteidigung einschließlich
        aller Gerichts- und Anwaltskosten in gesetzlicher Höhe. Dies gilt nicht,
        wenn die Rechtsverletzung vom Kunden nicht zu vertreten ist. Der Kunde
        ist verpflichtet, dem Verkäufer im Falle einer Inanspruchnahme durch
        Dritte unverzüglich, wahrheitsgemäß und vollständig alle Informationen
        zur Verfügung zu stellen, die für die Prüfung der Ansprüche und eine
        Verteidigung erforderlich sind.
        <br />
        9.3 Der Verkäufer behält sich vor, Verarbeitungsaufträge abzulehnen,
        wenn die vom Kunden hierfür überlassenen Inhalte gegen gesetzliche oder
        behördliche Verbote oder gegen die guten Sitten verstoßen. Dies gilt
        insbesondere bei Überlassung verfassungsfeindlicher, rassistischer,
        fremdenfeindlicher, diskriminierender, beleidigender, Jugend
        gefährdender und/oder Gewalt verherrlichender Inhalte.
        <br />
        <br />
        10) Anwendbares Recht
        <br />
        Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der
        Bundesrepublik Deutschland unter Ausschluss der Gesetze über den
        internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt diese
        Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende
        Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen
        gewöhnlichen Aufenthalt hat, entzogen wird.
        <br />
        <br />
        11) Alternative Streitbeilegung
        <br />
        11.1 Die EU-Kommission stellt im Internet unter folgendem Link eine
        Plattform zur Online-Streitbeilegung bereit: ec.europa.eu/consumers/odr
        <br />
        Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung
        von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an
        denen ein Verbraucher beteiligt ist.
        <br />
        11.2 Der Verkäufer ist zur Teilnahme an einem Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
      </div>
    </div>
  );
}
