import React from "react";
import Headline from "../Headline";

export default function SparklingArt() {
  return (
    <div>
      <Headline title="Sparkelized Art - coming soon" />
      <div style={{height: 800}} />
    </div>
  );
}
