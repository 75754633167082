import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home.tsx";
import NotFound from "./pages/NotFound.tsx";
import AboutMe from "./pages/AboutMe.tsx";
import Contact from "./pages/Contact.tsx";
import FashionIllustrations from "./pages/FashionIllustrations.tsx";
import MakingOf from "./pages/MakingOf.tsx";
import Photography from "./pages/Photography.tsx";
import EquinePhotography from "./pages/EquinePhotography.tsx";
import Gallery from "./pages/Gallery.tsx";
import Products from "./pages/Products.tsx";
import SparklingArt from "./pages/SparklingArt.tsx";
import Shop from "./pages/Shop.tsx";
import Impressum from "./pages/Impressum.tsx";
import Datenschutz from "./pages/Datenschutz.tsx";
import AGB from "./pages/AGB.tsx";
import Widerrufsbelehrung from "./pages/Widerrufsbelehrung.tsx";
export default function Main() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <NotFound />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/about",
      element: <AboutMe />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/fashion-illustrations",
      element: <FashionIllustrations />,
    },
    {
      path: "/making-of",
      element: <MakingOf />,
    },
    {
      path: "/photography",
      element: <Photography />,
    },
    {
      path: "/equine-photography",
      element: <EquinePhotography />,
    },
    {
      path: "/gallery",
      element: <Gallery />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/sparkling-art",
      element: <SparklingArt />,
    },
    {
      path: "/shop",
      element: <Shop />,
    },
    {
      path: "/impressum",
      element: <Impressum />,
    },
    {
      path: "/datenschutz",
      element: <Datenschutz />,
    },
    {
      path: "/agb",
      element: <AGB />,
    },
    {
      path: "/widerrufsbelehrung",
      element: <Widerrufsbelehrung />,
    },
  ]);
  return <RouterProvider router={router} />;
}
