import React from "react";
import Headline from "../Headline";
import Gold1 from "../resources/gallery/gold_1.webp";
import Gold2 from "../resources/gallery/gold_2.webp";
import Gold3 from "../resources/gallery/gold_3.webp";
import Herbst1 from "../resources/gallery/herbst_1.webp";
import Herbst2 from "../resources/gallery/herbst_2.webp";
import Herbst3 from "../resources/gallery/herbst_3.webp";
import Herbst4 from "../resources/gallery/herbst_4.webp";
import Herbst5 from "../resources/gallery/herbst_5.webp";
import Kranz1 from "../resources/gallery/kranz_1.webp";
import Kranz2 from "../resources/gallery/kranz_2.webp";
import Kranz3 from "../resources/gallery/kranz_3.webp";
import Kranz4 from "../resources/gallery/kranz_4.webp";
import Kranz5 from "../resources/gallery/kranz_5.webp";
import Kranz6 from "../resources/gallery/kranz_6.webp";
import Kranz7 from "../resources/gallery/kranz_7.webp";
import Kranz8 from "../resources/gallery/kranz_8.webp";
import Sommer1 from "../resources/gallery/sommer_1.webp";
import Sommer2 from "../resources/gallery/sommer_2.webp";

export default function Gallery() {
  return (
    <div>
      <Headline title="Photography" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {[
          [Gold1, Herbst2, Kranz8],
          [Gold2, Kranz3, Herbst4, Sommer1],
          [Gold3, Kranz4, Herbst5],
          [Kranz1, Herbst3, Kranz6],
          [Kranz2, Kranz5, Herbst1, Kranz7, Sommer2]
        ].map((row, i) => (
          <div
            style={{
              width: "19%",
              minWidth: 0,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {row.map((img, j) => (
                <img
                  src={img}
                  alt=""
                  style={{
                    minWidth: 0,
                    marginBottom: "12px",
                    boxShadow: "1px 1px 5px grey",
                  }}
                  key={i * 100 + j}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
