import React from "react";
import Headline from "../Headline.tsx";
import cinque_terre2 from "../resources/cinque_terre2.webp";

export default function Contact() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <img style={{ width: "49.5%" }} src={cinque_terre2} alt="" />
      <div style={{ width: "49.5%" }}>
        <Headline title="Contact" />
        <p>Michi's Illustrations</p>
        <p>
          Email:{" "}
          <a href="mailto:michelle.y.illustrations@gmail.com">
            michelle.y.illustrations@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
}
