import ZaphiraGold2 from "../resources/zaphira_gold_2.webp"
import Riva from "../resources/riva.webp"
import ZweiPferdeKraenze from "../resources/zwei_pferde-kraenze.webp"

export default function EquinePhotography() {
    const width = window.innerWidth >= 750 ? "50%" : "100%";
    return <div>
        <div style={{fontSize: 40, textAlign: "center", margin: "80px 0"}}>Equine Photography</div>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            <img style={{width: width}} src={ZaphiraGold2} alt="" />
            <div style={{width: width, textAlign: "center", padding: "50px 10px", boxSizing: "border-box"}}>
                <h2>Leistungen</h2>
                <ul style={{listStyleType: "none"}}>
                    <li style={{marginTop: 8}}>Ca. 1 Stunde Shooting Zeit</li>
                    <li style={{marginTop: 8}}>Alle Tiere eines Besitzers inklusive</li>
                    <li style={{marginTop: 8}}>Verschiedene Hintergründe möglich (Reitbilder, Portrait, usw.)</li>
                    <li style={{marginTop: 8}}>3 bearbeitete Bilder in digitaler Form inklusive</li>
                    <li style={{marginTop: 8}}>Zusätzliche digitale Bilder gegen Aufpreis</li>
                </ul>
            </div>
            <div style={{width: width, textAlign: "center", padding: "50px 10px", boxSizing: "border-box"}}>
                <h2>Preise</h2>
                <ul style={{listStyleType: "none"}}>
                    <li style={{marginTop: 8}}>Ca. 1 Stunde Shooting (inkl. 3 Bildern) <b>50€</b></li>
                    <li style={{marginTop: 8}}>Jedes zusätzliche Bild <b>12€</b></li>
                </ul>
            </div>
            <img style={{width: width}} src={Riva} alt="" />
            <img style={{width: width}} src={ZweiPferdeKraenze} alt="" />
            <div style={{width: width, textAlign: "center", padding: "50px 10px", boxSizing: "border-box"}}>
                <h2>Sammel-Fotoshootings</h2>
                <ul style={{listStyleType: "none"}}>
                    <li style={{marginTop: 8}}>Um ein Fotoshooting anzufragen, schreib mir einfach eine E-Mail an:</li>
                    <li style={{marginTop: 8}}><a href="mailto:michelle.y.illustration@gmail.com">michelle.y.illustration@gmail.com</a></li>
                    <li style={{marginTop: 8}}>Es sind auch Sammel-Fotoshootings ab 3 Leuten möglich, bei denen die Fotoshooting-Gebühr entfällt und lediglich die ausgewählten Fotos für 12€/Stück bezahlt werden müssen.</li>
                </ul>
            </div>
        </div>
    </div>
}