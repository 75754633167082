import React from "react";
import Headline from "../Headline.tsx";
import cindarella from "../resources/cinderella.webp";

export default function AboutMe() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <img style={{ width: "49.5%" }} src={cindarella} alt="" />
      <div style={{ width: "49.5%" }}>
        <Headline title="About Me" />
        <p>Hey there and thank you for visiting my website!</p>
        <br />
        <p>
          My name is Michelle and I am a hobby illustrator and photographer.
          I've been drawing since I was young and I got into photography a few
          years ago. I take pictures during my travels and want to share them
          with the world.
        </p>
        <br />
        <p>
          In case you like any of my work, send me an e-mail or visit my Etsy
          shop called MichisIllustrations. I do custom illustrations as well!
        </p>
      </div>
    </div>
  );
}
