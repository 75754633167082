import Footer from "./Footer.tsx";
import Header from "./Header.tsx";
import Main from "./Main.tsx";

function App() {
  return (
    <div style={{maxWidth: "1000px", margin: "auto", height: "100%", position: "relative"}}>
    <Header />
    <Main />
    <Footer />
    </div>
  );
}

export default App;
