import React from "react";
import { Link } from "@mui/material";
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';

export default function Footer() {
  const links = [
    {
      display: "Impressung",
      link: "/impressum",
    },
    {
      display: "Datenschutz",
      link: "/datenschutz",
    },
    {
      display: "AGB",
      link: "/agb",
    },
    {
      display: "Widerrufsbelehrung",
      link: "/agb",
    },
  ];
  const flexDirection = window.innerWidth >= 600 ? "row" : "column"
  return (
    <div
      style={{
        backgroundColor: "#333",
        height: "100px",
        color: "white",
        marginTop: "10px",
        // position: "fixed",
        // bottom: "0",
        // left: 0,
        // right: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: flexDirection,
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row", margin: "5px 0" }}>
            {links.map(({display, link}) => <Link style={{textDecoration: "none", color: "white", margin: "0 10px"}} href={link} >{display}</Link>)}
          </div>
          <div style={window.innerWidth >= 600 ? {margin: "5px 0 5px 10px"} : {textAlign: "center", margin: "5px 0"}}>© 2024 Michi's Illustrations</div>
        </div>
        <div style={{display: "flex", flexDirection: "row", margin: "5px 0"}}>
            <Link style={{color: "white", margin: "0 10px"}} href="https://www.instagram.com/michis_photography_corner" ><InstagramIcon /></Link>
            <Link style={{color: "white", margin: "0 10px"}} href="https://www.pinterest.de/michelleyillustration/?invite_code=6431f8d39caa4bcfbd9861d1cc20b20e&sender=907194056096881250" ><PinterestIcon /></Link>
            <Link style={{color: "white", margin: "0 10px"}} href="https://www.instagram.com/michisillustrations" ><InstagramIcon /></Link>
        </div>
      </div>
    </div>
  );
}
