import Headline from "../Headline";
import Beachy from "../resources/fashion-iillustrations/beachy.webp";
import BearingGifts from "../resources/fashion-iillustrations/bearing-gifts.webp";
import BlueBalloones from "../resources/fashion-iillustrations/blue-balloons.webp";
import Cheerful from "../resources/fashion-iillustrations/cheerful.webp";
import ChristmasOrnament from "../resources/fashion-iillustrations/christmas-ornament.webp";
import CustomIllustration from "../resources/fashion-iillustrations/custom-illustration.webp";
import Daisy from "../resources/fashion-iillustrations/daisy.webp";
import DerbyBubbles from "../resources/fashion-iillustrations/derby-bubbles.webp";
import EmeraldDream from "../resources/fashion-iillustrations/emerald-dream.webp";
import FloweryDream from "../resources/fashion-iillustrations/flowery-dream.webp";
import Greenery from "../resources/fashion-iillustrations/greenery.webp";
// import HolsteinerHorse from "../resources/fashion-iillustrations/holsteiner-horse.webp";
import Joy from "../resources/fashion-iillustrations/joy.webp";
import LadyInRed from "../resources/fashion-iillustrations/lady-in-red.webp";
import LadyWithAHat from "../resources/fashion-iillustrations/lady-with-a-hat.webp";
import Lifeguard from "../resources/fashion-iillustrations/lifeguard.webp";
import NewYearWishes from "../resources/fashion-iillustrations/new-year-wishes.webp";
import OatField from "../resources/fashion-iillustrations/oat-field.webp";
import OldenburgerHorse from "../resources/fashion-iillustrations/oldenburger-horse.webp";
import PurpleDreaming from "../resources/fashion-iillustrations/purple-dreaming.webp";
import RosesOnTulle from "../resources/fashion-iillustrations/roses-on-tulle.webp";
import RoyalBall from "../resources/fashion-iillustrations/royal-ball.webp";
import Royalty from "../resources/fashion-iillustrations/royalty.webp";
import RunFree from "../resources/fashion-iillustrations/run-free.webp";
import SpringBloom from "../resources/fashion-iillustrations/spring-bloom.webp";
import SummerPicnic from "../resources/fashion-iillustrations/summer-picnic.webp";
import Valentina from "../resources/fashion-iillustrations/valentina.webp";
import WatermelonSugar from "../resources/fashion-iillustrations/watermelon-sugar.webp";
import WhiteAsAFeather from "../resources/fashion-iillustrations/white-as-a-feather.webp";

export default function FashionIllustrations() {
  return (
    <>
      <Headline title="My Art" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {[
          WhiteAsAFeather,
          SummerPicnic,
          Valentina,
          Greenery,
          BlueBalloones,
          PurpleDreaming,
          RunFree,
          Cheerful,
          EmeraldDream,
          OatField,
          NewYearWishes,
          CustomIllustration,
          BearingGifts,
          ChristmasOrnament,
          DerbyBubbles,
          WatermelonSugar,
          SpringBloom,
          Lifeguard,
          Joy,
          Beachy,
          Royalty,
          FloweryDream,
          RoyalBall,
          LadyInRed,
          LadyWithAHat,
          Daisy,
          RosesOnTulle,
          OldenburgerHorse,
          // HolsteinerHorse,
        ].map((img, i) => (
          <img
            src={img}
            alt=""
            style={{
              minWidth: "24%",
              width: 1,
              marginBottom: "12px",
              boxShadow: "1px 1px 5px lightgrey",
            }}
            key={i}
          />
        ))}
      </div>
    </>
  );
}
