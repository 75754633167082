import Headline from "../Headline"
import GluecksbringerHerz from "../resources/gluecksbringer_herz.webp"
import GluecksbringerHund from "../resources/gluecksbringer_hund.webp"
import BuchFarbschnitt from "../resources/buch_farbschnitt.webp"

export default function Products() {
    return <div>
        <Headline title="Products" />
        <div style={{marginBottom: 8}}>
            Die hier aufgeführten Produkte könnt ihr bei mir bestellen indem ihr eine E-Mail an <a href="mailto:michelle.y.illustration@gmail.com">michelle.y.illustration@gmail.com</a> schreibt.
        </div>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between"}}>
            <div style={{position: "relative", padding: "0 8px 4px 0"}}>
                <img src={GluecksbringerHerz} alt="" />
                <div style={{backgroundColor: "lightgray", padding: "24px 8px 8px 8px", position: "absolute", bottom: 0, left: 16, right: 0}}>Glücksbringer Herz</div>
            </div>
            <div style={{position: "relative", padding: "0 8px 4px 0"}}>
                <img src={GluecksbringerHund} alt="" />
                <div style={{backgroundColor: "lightgray", padding: "24px 8px 8px 8px", position: "absolute", bottom: 0, left: 16, right: 0}}>Glücksbringer Hund</div>
            </div>
            <div style={{position: "relative", padding: "0 8px 4px 0"}}>
                <img src={BuchFarbschnitt} alt="" />
                <div style={{backgroundColor: "lightgray", padding: "24px 8px 8px 8px", position: "absolute", bottom: 0, left: 16, right: 0}}>Buch Farbschnitt handgemalt</div>
            </div>
        </div>
    </div>
}