import Headline from "../Headline";
import MichisIllustratioins from "../resources/making-of/michis_Illustrations.webp";
import WatermelonSugar from "../resources/making-of/watermelon_sugar.webp";
import RoughSketches from "../resources/making-of/rough_sketches.webp";
import Joy from "../resources/making-of/joy.webp";
import DigitalIllustrations from "../resources/making-of/digital_illustrtions.webp";
import FloweryDream from "../resources/making-of/flowery_dream.webp";
import NewYearWishes from "../resources/making-of/new_year_wishes.webp";

export default function MakingOf() {
  const width = window.innerWidth >= 750 ? "49.5%" : "100%";
  return (
    <div>
      <img style={{ width: "100%" }} src={MichisIllustratioins} alt="" />
      <br />
      <br />
      <Headline title="Making Of" />
      <p>
        On this page you will find some extracts from my work on the
        illustrations I offer in my Etsy Shop!
      </p>
      <br />
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {[
            { img: WatermelonSugar, text: "Watermelon Sugar" },
            { img: RoughSketches, text: "Rough Sketches" },
            { img: Joy, text: "Joy" },
            { img: DigitalIllustrations, text: "Digital Illustrations" },
            { img: FloweryDream, text: "Flowery Dream" },
            { img: NewYearWishes, text: "New Year Wishes" },
          ].map(({ img, text }, i) => (
            <div style={{ width: width }}>
              <img style={{ width: "100%" }} src={img} alt="" />
              <div
                style={{
                  padding: 16,
                  fontWeight: "bold",
                  fontSize: 24,
                  width: "100%",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  marginTop: -4,
                  marginBottom: 8
                }}
              >
                {text}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
