import Watermelon from "../resources/watermelon.webp"
import Italien from "../resources/italien.webp"

export default function Shop() {
    const width = window.innerWidth >= 750 ? "50%" : "100%";
    return <div>
        <div style={{fontSize: 40, textAlign: "center", margin: "80px 0"}}>Etsy Shop & Blog</div>
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center"}}>
            <img style={{width: width}} src={Watermelon} alt="" />
            <div style={{width: width, textAlign: "center", padding: "50px 10px 80px 10px", boxSizing: "border-box"}}>
                <h2 style={{marginBottom: 24}}>MichisIllustrations</h2>
                <a href="https://www.etsy.com/shop/MichisIllustrations" style={{textDecoration: "none", backgroundColor: "lightyellow", padding: 12, borderRadius: 100, color: "black"}} target="_blank" rel="noreferrer" >SHOP MY ART</a>
            </div>
            <div style={{width: width, textAlign: "center", padding: "50px 10px 80px 10px", boxSizing: "border-box"}}>
                <h2 style={{marginBottom: 12}}>Travel Blog</h2>
                <p style={{marginBottom: 24}}>If you feel like reading about travelling, feel free to</p>
                <a href="https://lifeandthecity753489359.wordpress.com/" style={{textDecoration: "none", backgroundColor: "lightgreen", padding: 12, borderRadius: 100, color: "black"}} target="_blank" rel="noreferrer" >VISIT MY BLOG</a>
            </div>
            <img style={{width: width}} src={Italien} alt="" />
        </div>
    </div>
}