import React from "react";
import zaphira_gold from "../resources/zaphira_gold.webp";
import orange_in_grass from "../resources/orange_in_grass.webp";
import HR from "../HR.tsx";

export default function Home() {
  return (
    <>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <img style={{maxWidth: "49.5%"}} src={zaphira_gold} alt="" />
        <img style={{maxWidth: "49.5%"}} src={orange_in_grass} alt="" />
      </div>
      <HR />
      <div style={{textAlign: "center"}}>"Great art picks up where nature ends."</div>
      <div style={{textAlign: "center"}}>- Mark Chagall</div>
      <HR />
    </>
  );
}
