import Headline from "../Headline";
import Amsterdam from "../resources/photography/amsterdam.webp";
import Blautopf from "../resources/photography/blautopf.webp";
import Cambria from "../resources/photography/cambria.webp";
import CinqueTerre from "../resources/photography/cinque_terre.webp";
import CinqueTerre2 from "../resources/photography/cinque_terre_2.webp";
import ElgolBeach from "../resources/photography/elgol_beach.webp";
import FairyPools from "../resources/photography/fairy_pools.webp";
import Groetzingen from "../resources/photography/groetzingen.webp";
import NeistPointLighthouse from "../resources/photography/neist_point_lighthouse.webp";
import Regello from "../resources/photography/regello.webp";
import SequioiaNationalParc from "../resources/photography/sequioia_national_parc.webp";
import Venice from "../resources/photography/venice.webp";
import Venice2 from "../resources/photography/venice_2.webp";
import YosemiteNationalParc from "../resources/photography/yosemite_national_parc.webp";

export default function Photography() {
  return (
    <div>
      <Headline title="Photography" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {[
          [Blautopf, SequioiaNationalParc, Regello],
          [CinqueTerre, Amsterdam, Venice, CinqueTerre2],
          [ElgolBeach, Cambria, Venice2, FairyPools],
          [Groetzingen, YosemiteNationalParc, NeistPointLighthouse],
        ].map((row, i) => (
          <div
            style={{
              width: "24%",
              minWidth: 0,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {row.map((img, j) => (
                <img
                  src={img}
                  alt=""
                  style={{
                    minWidth: 0,
                    marginBottom: "12px",
                    boxShadow: "1px 1px 5px grey",
                  }}
                  key={i * 100 + j}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
