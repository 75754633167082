import React from "react";
import Headline from "../Headline";

export default function Impressum() {
  return (
    <div style={{minHeight: "800px"}}>
      <Headline title="Impressum" />
      <div>
        Michelle Yasmine Laskowski <br />
        Michi's Illustrations <br />
        E-Mail:{" "}
        <a style={{color: "black", textDecoration: "none"}} href="mailto:michelle.y.illustration@gmail.com">
          michelle.y.illustration@gmail.com
        </a>
        <br />
        Phone: <a style={{color: "black", textDecoration: "none"}} href="tel:+4915168439502">+49 151 68439502</a>
      </div>
    </div>
  );
}
