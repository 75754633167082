import React from "react";
import Headline from "../Headline";

export default function Datenschutz() {
  return (
    <div>
      <Headline title="Datenschutz" />
      <p>
        1) Information über die Erhebung personenbezogener Daten und
        Kontaktdaten des Verantwortlichen
        <br />
        1.1 Wir freuen uns, dass Sie unseren Wix-Auftritt/Etsy-Auftritt
        (nachfolgend „Website“) besuchen und bedanken uns für Ihr Interesse. Im
        Folgenden informieren wir Sie über den Umgang mit Ihren
        personenbezogenen Daten bei Nutzung unserer Website. Personenbezogene
        Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert
        werden können.
        <br />
        1.2 Verantwortlicher für die Datenverarbeitung im Sinne der
        Datenschutz-Grundverordnung (DSGVO) ist Michelle Yasmine Laskowski,
        Michi's Illustrations, Deutschland, Tel.: +4915168439502, E-Mail:
        michelle.y.illustrations@gmail.com. Der für die Verarbeitung von
        personenbezogenen Daten Verantwortliche ist diejenige natürliche oder
        juristische Person, die allein oder gemeinsam mit anderen über die
        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        entscheidet.
        <br />
        <br />
        2) Kontaktaufnahme
        <br />
        Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
        E-Mail) werden personenbezogene Daten erhoben, welche dies sind, können
        Sie aus dem Kontaktformular ersehen. Diese Daten werden ausschließlich
        zum Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme
        und die damit verbundene technische Administration gespeichert und
        verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser
        berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6
        Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines
        Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung
        Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschließender
        Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den
        Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend
        geklärt ist und sofern keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen.
        <br />
        <br />
        3) Datenverarbeitung zur Bestellabwicklung
        <br />
        3.1 Die von uns erhobenen personenbezogenen Daten werden im Rahmen der
        Vertragsabwicklung an das mit der Lieferung beauftragte
        Transportunternehmen weitergegeben, soweit dies zur Lieferung der Ware
        erforderlich ist. Ihre Zahlungsdaten geben wir im Rahmen der
        Zahlungsabwicklung an das beauftragte Kreditinstitut weiter, sofern dies
        für die Zahlungsabwicklung erforderlich ist. Sofern
        Zahlungsdienstleister eingesetzt werden, informieren wir hierüber
        nachstehend explizit. Die Rechtsgrundlage für die Weitergabe der Daten
        ist hierbei Art. 6 Abs. 1 lit. b DSGVO.
        <br />
        3.2 Bei Nutzung der Zahlungsmethode Etsy Payments erfolgt die
        Zahlungsabwicklung über die Etsy Ireland UC, 66/67 Great Strand Street,
        Dublin 1, Irland (im Folgenden: Etsy UC), es sei denn, die Zahlung
        erfolgt in den Währungen US-Dollar oder kanadische Dollar. In diesem
        Fall erfolgt die Zahlungsabwicklung über die die Etsy Inc., 117 Adams
        Street, Brooklyn, NY 11201 , Vereinigte Staaten von Amerika (im
        Folgenden: Etsy Inc.). Die Weitergabe erfolgt gemäß Art. 6 Abs. 1 lit. b
        DSGVO und nur insoweit, als dies für die Zahlungsabwicklung erforderlich
        ist.
        <br />
        Weitere datenschutzrechtliche Informationen entnehmen Sie bitte der
        Datenschutzerklärung von Etsy: etsy.com/de/legal/privacy/
        <br />
        3.3 Bei Zahlung per Kreditkarte via PayPal oder per Lastschrift via
        PayPal geben wir Ihre Zahlungsdaten im Rahmen der Zahlungsabwicklung an
        die PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal,
        L-2449 Luxembourg (nachfolgend "PayPal"), weiter. Die Weitergabe erfolgt
        gemäß Art. 6 Abs. 1 lit. b DSGVO und nur insoweit, als dies für die
        Zahlungsabwicklung erforderlich ist.
        <br />
        <br />
        4) Rechte des Betroffenen
        <br />
        4.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem
        Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen
        Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte),
        über die wir Sie nachstehend informieren:
        <br />
        - Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein Recht
        auf Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten,
        die Verarbeitungszwecke, die Kategorien der verarbeiteten
        personenbezogenen Daten, die Empfänger oder Kategorien von Empfängern,
        gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
        Speicherdauer bzw. die Kriterien für die Festlegung der Speicherdauer,
        das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
        Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer
        Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns
        bei Ihnen erhoben wurden, das Bestehen einer automatisierten
        Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige
        Informationen über die involvierte Logik und die Sie betreffende
        Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung,
        sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46 DSGVO
        bei Weiterleitung Ihrer Daten in Drittländer bestehen;
        <br />
        - Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht auf
        unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder
        Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten;
        <br />
        - Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die
        Löschung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen
        des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch
        insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts
        auf freie Meinungsäußerung und Information, zur Erfüllung einer
        rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder
        zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        erforderlich ist;
        <br />
        - Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie
        haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene
        Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer
        Daten wegen unzulässiger Datenverarbeitung ablehnen und stattdessen die
        Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre
        Daten zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigen, nachdem wir diese Daten nach Zweckerreichung
        nicht mehr benötigen oder wenn Sie Widerspruch aus Gründen Ihrer
        besonderen Situation eingelegt haben, solange noch nicht feststeht, ob
        unsere berechtigten Gründe überwiegen;
        <br />
        - Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht auf
        Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
        Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen
        Empfängern, denen die Sie betreffenden personenbezogenen Daten
        offengelegt wurden, diese Berichtigung oder Löschung der Daten oder
        Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist
        sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand
        verbunden. Ihnen steht das Recht zu, über diese Empfänger unterrichtet
        zu werden.
        <br />
        - Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das
        Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
        in einem strukturierten, gängigen und maschinenlesebaren Format zu
        erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen, soweit dies technisch machbar ist;
        <br />
        - Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO:
        Sie haben das Recht, eine einmal erteilte Einwilligung in die
        Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft zu
        widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten
        unverzüglich löschen, sofern eine weitere Verarbeitung nicht auf eine
        Rechtsgrundlage zur einwilligungslosen Verarbeitung gestützt werden
        kann. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
        aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
        berührt;
        <br />
        - Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht sind,
        dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
        die DSGVO verstößt, haben Sie - unbeschadet eines anderweitigen
        verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf
        Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
        Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des
        mutmaßlichen Verstoßes.
        <br />
        4.2 WIDERSPRUCHSRECHT
        <br />
        WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN
        AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN,
        HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER
        BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT
        WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
        <br />
        MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
        VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER
        VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
        VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND
        GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER
        GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.
        <br />
        WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
        DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH
        GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
        ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE
        OBEN BESCHRIEBEN AUSÜBEN.
        <br />
        MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE
        VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
        <br />
        <br />
        5) Dauer der Speicherung personenbezogener Daten
        <br />
        Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
        anhand der jeweiligen Rechtsgrundlage, am Verarbeitungszweck und –
        sofern einschlägig – zusätzlich anhand der jeweiligen gesetzlichen
        Aufbewahrungsfrist (z.B. handels- und steuerrechtliche
        Aufbewahrungsfristen).
        <br />
        Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer
        ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO werden
        diese Daten so lange gespeichert, bis der Betroffene seine Einwilligung
        widerruft.
        <br />
        Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen
        rechtsgeschäftlicher bzw. rechtsgeschäftsähnlicher Verpflichtungen auf
        der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet werden, werden
        diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht,
        sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung
        erforderlich sind und/oder unsererseits kein berechtigtes Interesse an
        der Weiterspeicherung fortbesteht.
        <br />
        Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art.
        6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis der
        Betroffene sein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausübt, es
        sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen
        Person überwiegen, oder die Verarbeitung dient der Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen.
        <br />
        Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der
        Direktwerbung auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden diese
        Daten so lange gespeichert, bis der Betroffene sein Widerspruchsrecht
        nach Art. 21 Abs. 2 DSGVO ausübt.
        <br />
        Sofern sich aus den sonstigen Informationen dieser Erklärung über
        spezifische Verarbeitungssituationen nichts anderes ergibt, werden
        gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie
        für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
        wurden, nicht mehr notwendig sind.
        <br />
        <br />
        6) Hinweis auf die Etsy-Datenschutzerklärung
        <br />
        Für alle weiteren Datenverarbeitungen, die über die vorstehend
        beschriebenen Datenverarbeitungen hinausgehen, ist die Plattform Etsy
        verantwortlich. Weitere Informationen zum Datenschutz von Etsy können
        Sie in den Datenschutzhinweisen von Etsy einsehen:
        etsy.com/de/legal/privacy/?ref=ftr
      </p>
    </div>
  );
}
