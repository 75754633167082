import { Accordion, AccordionDetails, AccordionSummary, Link } from "@mui/material";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
  return (
    <>
      <Title />
      <NavBar />
    </>
  );
}

function NavBar() {
  const links = [
    { display: "Home", link: "/home" },
    { display: "About me", link: "/about" },
    { display: "Contact", link: "/contact" },
    { display: "Fashion Illustrations", link: "/fashion-illustrations" },
    { display: "Making Of", link: "/making-of" },
    { display: "Photography", link: "/photography" },
    { display: "Equine Photography", link: "/equine-photography" },
    { display: "Gallery", link: "/gallery" },
    { display: "Products", link: "/products" },
    { display: "Sparkling Art", link: "/sparkling-art" },
    { display: "Shop", link: "/shop" },
  ];
  if(window.innerWidth >= 750) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          backgroundColor: "#333",
          color: "white",
          padding: "5px 0",
          fontSize: "12px",
          marginBottom: "10px"
        }}
      >
        {links.map(({ display, link }) => (
          <Link style={{textDecoration: "none", color: "white"}} href={link}>{display}</Link>
        ))}
      </div>
    );
  } else {
    return <Accordion style={{marginBottom: "10px"}}>
      <AccordionSummary style={{backgroundColor: "#333"}}>
        <MenuIcon style={{color: "white"}} />
      </AccordionSummary>
      <AccordionDetails>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#333",
          color: "white",
          padding: "5px 0",
          fontSize: "12px", textAlign: "center", lineHeight: "22px"
        }}
      >
        {links.map(({ display, link }) => (
          <Link style={{textDecoration: "none", color: "white"}} href={link}>{display}</Link>
        ))}
      </div>
      </AccordionDetails>
    </Accordion>
  }
}

function Title() {
  return (
    <div
      style={{
        width: "fit-content",
        padding: "50px 0 30px 0",
        margin: "auto"
      }}
    >
      <p style={{ fontSize: "32px" }}>
        <b>Michi's</b>
        <span style={{ fontWeight: 100 }}>Illustrations</span>
      </p>
      <div style={{ border: "1px solid black", margin: "0px 40px" }} />
      <p style={{ textAlign: "center", lineHeight: "28px" }}>
        Fashion Illustrations & Photography
      </p>
    </div>
  );
}
